/* --------------------------
テーブル
-------------------------- */

.c-table {
  border: 1px solid $color-gray-light;
  width: 100%;
  font-size: nth($font-size, 7);
  line-height: nth($line-height, 5);

  @include view-at(sp) {
    font-size: 14px;
  }

  tbody {
    tr {
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        border-bottom: 0;
      }
    }
    th {
      padding: 15px;
      line-height: 1.5;
      font-weight: normal;
      width: 200px;
      font-weight: $bold;
      background: $color-gray-snow;
      border-right: 1px solid $color-gray-light;

      @include view-at(sp) {
        padding: 10px;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    td {
      padding: 15px;
      line-height: 1.5;

      @include view-at(sp) {
        display: block;
        width: 100%;
        border-bottom: 1px solid $color-gray;
        font-size: 12px;
      }
    }
  }

  &--narrow {
    border-color: $color-gray;
    tbody {
      tr {
        border-color: $color-gray;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }

  &-lifeInfo {
    width: 100%;
    font-size: nth($font-size, 7);

    @include view-at(sp) {
      margin-bottom: 40px;
    }

    tbody {
      tr {
        border-bottom: 1px solid $color-gray-light;
        display: flex;
        justify-content: space-between;
      }

      td {
        padding: 10px 0;
        line-height: 1.5;

        @include view-at(sp) {
          font-size: 13px;
        }

        span {
          font-size: 80%;
        }
      }
    }

    &-ttl{
      text-align: left;
      margin-bottom: 0;
      font-size: 20px;

      @include view-at(sp) {
        font-size: 18px;
      }

      i {
        color: $color-gray-light;
        padding-right: 10px;
      }
    }

    &-name {
      b {
        background: $color-red;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        color: $color-white;
        margin-right: 5px;
        font-family: 'Noto Serif JP';

        @include view-at(sp) {
          width: 20px;
          height: 20px;
        }

        &.green {
          background: $color-green;
        }
        &.orange {
          background: #F08200;
        }
        &.blue {
          background: #0C6097;
        }
        &.Yellow-green {
          background: #6EB92C;
        }
      }
    }
  }

  &-company {
    border: none;

    @include view-at(sp) {
      font-size: 14px;
    }

    tbody {
      tr {
        display: flex;
        justify-content: space-between;
        border: none;

        @include view-at(sp) {
          display: block;
          font-size: 14px;
        }
        
        th {
          border: none;
          border-bottom: 1px solid $color-gray-light;
          background-color: $color-white;
          margin-right: 20px;
          padding: 20px 0;

          @include view-at(sp) {
            border: none;
            background: $color-brown-snow;
            padding: 10px 0;
          }
        }

        td {
          border-bottom: 1px solid $color-gray-light;
          width: 100%;

          @include view-at(sp) {
            border: none;
          }
        }

        &:nth-child(1) {
          th, td {
            border-top: 1px solid $color-gray-light;

            @include view-at(sp) {
              border: none;
            }
          }
        }
      }
    }

  }
}
