
body {
  font-size: 13px;
  font-family: fot-tsukubrdgothic-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8;
  color: white;
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}

p {
  text-align: justify;
  margin-bottom: 10px;
  font-size: 18px;
  color: white;
  line-height: 1.5;

  @include view-at(sp) {
    text-align: left;
    font-size: 12px;
    line-height: 1.3;
  }
  @include view-at(se) {
    margin-bottom: 5px;
  }
}


img {
  max-width: 100%;
  vertical-align: middle;
}

.rel {
  position: relative;
}

.nolink {
  pointer-events: none;
  opacity: 0.6;

  a {
    pointer-events: none;
  }
}

.shadow {
  -webkit-filter: drop-shadow(5px 5px 5px #c7c7c7);
  filter: drop-shadow(5px 5px 5px #c7c7c7);
}
