
.p-footer {
	position: relative;
	border-top: 20px solid $color-main;
	padding-bottom: 110px;
	background: white;
	font-family: "Noto Sans JP";

	&-copylight{
		position: fixed;
    z-index: 9999;
		bottom: 10px;
		width: 100%;
		text-align: center;
		color: white;

		@include view-at(sp) {
			font-size: 8px;
			bottom: 5px;
		}
	}
	
	&-pagetop{
		position: fixed;
    z-index: 9999;
    bottom: 20px;
    right: 20px;

		@include view-at(sp) {
			bottom: 0;
			right: 0px;
		}

		a{
			width: 45px;
			height: 45px;
			line-height: 45px;
			background: #696864;
			color: #fff;
			font-weight: bold;
			text-align: center;
			display: block;

			span {
				display: block;
			}
		}
	}
}
