
/* --------------------------
color
-------------------------- */
$color-main: #CEC6A5;
$color-sub: #0080AD;
$color-text: #1C1C1C;

$color-black: #111;
$color-white: #fff;

$color-red: #e20013;
$color-red-dark: #D04255;
$color-red-light: #f8ebed;

$color-yellow: #E4D200;

$color-blue: #009dbf;
$color-blue-light: #41617E;
$color-blue-snow: #E5F2FA;
$color-blue-dark: #003363;

$color-gold: #dbc583;
$color-gold-light: #ede2c1;
$color-gold-dark: #A18733;

$color-orange: #C15303;
$color-pink: #f8ebed;
$color-green: #007535;
$color-green-light: #d1f2ec;


$color-gray: #ababab;
$color-gray-light: #c5c5c5;
$color-light-gray: #c5c5c5;
$color-gray-dark: #212529;
$color-gray-snow: #ebeaea;

$color-brown: #877658;
$color-brown-snow: #EDECE7;
$color-brown-light: #9C8968;
$color-brown-dark: #5C4F3C;




/* --------------------------
spacing
-------------------------- */
$space: 100px, 40px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 50px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 38px, 28px, 24px, 22px, 20px, 18px, 16px, 14px, 12px;
$line-height: 68px, 30px, 30px, 22px, 20px, 18px;

$font-size-sp: 26px, 24px, 22px, 20px, 18px, 16px, 14px, 12px;
$line-height-sp: 40px, 36px, 32px, 22px, 16px, 16px;

$bold: 500;
$normal: 300;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1020px;
$contents-middle-width: 1000px;
$contents-narrow-width: 850px;
$contents-small-width: 600px;
$contents-margin: 20px;
$contents-margin-sp: 20px;
$contents-padding: 20px;
$contents-padding-sp: 20px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  header,
  footer,
  modal
);
