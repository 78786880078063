/* --------------------------
リスト
-------------------------- */

.c-list{
  font-size: nth($font-size, 4);

  >li {
    margin-bottom: 5px;
    line-height: 24px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &--point {
    >li {
      padding-left: 25px;
      margin-bottom: 10px;
      line-height: 1.5em;
      position: relative;
      font-size: 16px;
      background: url(../images/common/list.png) no-repeat left top;
      background-size: 20px ;

      @include view-at(sp) {
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--point-min {
    >li {
      padding-left: 25px;
      margin-bottom: 5px;
      line-height: 1.5em;
      position: relative;
      font-size: 14px;
      background: url(../images/common/list.png) no-repeat left top;
      background-size: 20px ;

      @include view-at(sp) {
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--square {
    > li {
      padding-left: 15px;
      margin-bottom: 10px;
      line-height: 24px;
      position: relative;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background-color: $color-sub;
        position: absolute;
        top: 8px;
        left: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--link {
    li {
      padding-left: 15px;
      margin-bottom: 5px;
      line-height: 24px;
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 6.9px;
        border-color: transparent transparent transparent $color-gray;
        position: absolute;
        top: 9px;
        left: 2px;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }


  &--horizontal {
    display: flex;
    align-items: center;
    li {
      margin-left: 25px;
      margin-bottom: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--border {
    li {
      background-position: left 16px;
      padding: 14px;
      border-bottom: 1px dashed #d4d4d4;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1;

      &:last-child {
        border-bottom: 0;
      }
      a {
        color: $color-black;
        text-decoration: none;

        &:hover {
          color: $color-black;
        }
      }
    }
  }

  &--order {
    padding-left: 15px;
    margin-bottom: 10px;
    line-height: 24px;
  }

  &--note {
    li {
      font-size: 13px;
      color: #8d8d8d;
      line-height: 1.5;
      &::before {
        content: '※';
        margin-right: 3px;
      }
    }
  }
}
