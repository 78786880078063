header {
	position: fixed;
	bottom: 20px;
	left: 20px;
	width: 100%;
	z-index: 9999;
	font-family: "Noto Sans JP";

	@include view-at(tab) {
		bottom: auto;
		top: 20px;
	}

	@include view-at(sp) {
		top: 5px;
		left: 10px;
	}

	#menu {
		li {
			display:inline-block;
			margin: 10px;

			@include view-at(sp) {
				margin: 0;
			}
		
			&.active{
				border-bottom: 1px solid white;

				a {
					padding: 9px 18px;
					display:block;
					color: #fff;

					
				}
				a:hover{
					border-bottom: 1px solid white;
				}
			}

			a{
				text-decoration:none;
				color: white;
				padding: 9px 18px;
				display:block;

				@include view-at(sp) {
					padding: 0 10px;
					font-size: 10px;
				}
				@include view-at(se) {
					padding: 0 5px;
				}
			}

			&:hover{
				border-bottom: 1px solid white;
			 }
		}
	}
}