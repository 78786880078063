/* --------------------------
背景色
-------------------------- */
.u-bg-main {
  background: $color-brown-snow;
}

.u-bg-sub {
  background: url(../images/common/sub-bg.jpg) no-repeat bottom center;
  background-size: cover;
}

.u-bg-grade {
  background: -moz-linear-gradient(left, $color-sub, $color-main,$color-sub);
  background: -moz-linear-gradient(left, $color-sub, $color-main,$color-sub);
  background: -moz-linear-gradient(left, $color-sub, $color-main,$color-sub);
}

.u-bg-light {
  background-color: $color-gray-light;
}
.u-bg-snow {
  background-color: $color-gray-snow;
}
.u-bg-white {
  background-color: $color-white;
}

.u-bg-green {
  background-color: $color-green-light;
}

.u-bg-blue {
  background-color: $color-blue;
}

.u-bg-blue-snow {
  background-color: $color-blue-snow;
}

.u-bg-gold {
  background-color: $color-gold;
}

.u-bg-blue-dark {
  background-color: $color-blue-dark;
}

.u-bg-red {
  background-color: $color-red-light;
}

.u-bg-brown-snow {
  background-color: $color-brown-snow;
}
