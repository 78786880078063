/* --------------------------
枠線
-------------------------- */

.u-border {
  &-bottom {
    border-bottom: 1px solid $color-sub;
  }

  &-top {
    border-top: 1px solid $color-red;
  }

  &-right {
    border-right: 1px solid $color-red;
  }

  &-left {
    border-left: 1px solid $color-red;
  }

  &--white {
    border-color: $color-white;
  }
}


.Text-Span {
  position: relative;
  z-index: 1;
}
.Text-Span:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 10px;
  background: $color-gold;
  z-index: -1;
  transition: all 1.5s;
}
.Text-Span.isActive:after {
  width: 100%;
}