/* --------------------------
セクション
-------------------------- */

.c-section {
  background: white;
  @include view-at(pc) {
    padding: nth($space, 1) 0;
  }

  @include view-at(sp) {
    padding: nth($space-sp, 1) 0;
  }

  &--middle {
    @include view-at(pc) {
      padding: nth($space, 2) 0;
    }

    @include view-at(sp) {
      padding: nth($space-sp, 2) 0;
    }
  }

  &--narrow {
    @include view-at(pc) {
      padding: nth($space, 3) 0;
    }

    @include view-at(sp) {
      padding: nth($space-sp, 3) 0;
    }
  }

  &--bg {
    @include view-at(pc) {
      padding: nth($space, 1) 0;
    }

    @include view-at(sp) {
      padding: nth($space-sp, 2) 0;
    }
  }


  &-container {
    max-width: $contents-width;
    margin: 0 auto;

    @include view-at(pc) {
    }

    @include view-at(tab) {
      max-width: 100%;
      padding: 0 20px;
    }

    @include view-at(sp) {
      max-width: 100%;
      padding: 0 $contents-padding-sp;
    }

    @include view-at(se) {
      max-width: 100%;
      padding: 0 10px;
    }

    &-wide {
      padding: 0 20px;
  
      @include view-at(sp) {
        padding: 0 $contents-padding-sp;
      }
  
      @include view-at(se) {
        padding: 0 10px;
      }
    }

    &-inner {
      max-width: 1000px;
      margin: auto;

      @include view-at(tab) {
        max-width: 100%;
        padding: 0 20px;
      }

      @include view-at(sp) {
        padding: 0;
      }
    }

    &-narrow {
      max-width: $contents-narrow-width;
      margin: auto;

      @include view-at(tab) {
        max-width: 100%;
        padding: 0 20px;
      }

      @include view-at(sp) {
        padding: 0;
      }
    }
  }

  &-bg{
    @include view-at(pc) {
      padding: nth($space, 1) 0;
    }
  
    @include view-at(sp) {
      padding: 40px 0;
    }
  }
}
