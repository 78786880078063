/* --------------------------
キャプション
-------------------------- */

.c-cap {
	font-size: 11px;
	margin: 2px 0 0;
	line-height: 1.5em;
	font-family: 'Noto Sans CJK JP';
	
	&-img{
		position: absolute;
		bottom: -12px;
		right: 0;
    line-height: 1em;
		font-size: 10px;
		text-align: right;
		font-family: 'Noto Sans CJK JP';
		color: $color-text;
		z-index: +1;

		&--l{
			position: absolute;
			bottom: -12px;
			left: 0;
			line-height: 1em;
			font-size: 10px;
			text-align: right;
			font-family: 'Noto Sans CJK JP';
		}
		
		&--on{
			position: absolute;
			margin: 0;
			bottom: 5px;
			right: 5px;
			line-height: 1em;
			font-size: 10px;
			text-align: right;
			font-family: 'Noto Sans CJK JP';

			&--white {
				position: absolute;
				margin: 0;
				bottom: 5px;
				right: 5px;
				line-height: 1em;
				font-size: 10px !important;
				text-align: right;
				font-family: 'Noto Sans CJK JP';
				color: $color-white;
			}
		}

		&--ttl{
			clear: both;
			span {
				float: left;
			}
			
		}

		&--bg{
			position: absolute;
		bottom: 0;
		right: 0;
		padding: 2px 10px;
    line-height: 1em;
		font-size: 10px;
		color: $color-white;
		background: rgba(0, 0, 0, 0.3);
		text-align: right;
		font-family: 'Noto Sans CJK JP';
		}
	}

	&-img-sub{
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 2px 5px;
		font-size: 10px;
		text-align: right;
		font-family: 'Noto Sans CJK JP';
	}

	&-wrap{
		padding: 10px 0 20px;

		@include view-at(tab) {
			padding: 10px 20px 20px;
		}

		@include view-at(sp) {
			padding: 10px 15px 20px;
		}

		@include view-at(se) {
			padding: 10px 10px 20px;
		}
	}

	&-r {
		text-align: right;
		font-size: 10px;
		margin: 2px 0 0;
		line-height: 1.5em;
	}
}
