/* --------------------------
ボタン
-------------------------- */

.c-button {
	display: inline-block;
	width: 100%;
	border: 1px solid $color-main;
	color:$color-main;
	font-size: 15px;
	letter-spacing: .08em;
	padding: 15px 40px;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	background-color: $color-white;
	position: relative;
	transition: all .3s ease-in-out;

	&:hover{
		opacity: 0.6;

		&::after{
			border-color:$color-white;
			right:12px;
		}
	}


	&--primary {
		border-color: transparent;
		background: $color-main;
		color:$color-white;
		padding: 10px 40px;
		position: relative;
		font-size: 14px;
		width: auto;

		&:hover{
			opacity: 1;
			border-color:$color-main;
			font-family: 'Noto Sans JP';
			background: $color-white;
			color:$color-main;
		}

		i {
			padding-right: 10px;
		}
	}

	&--scroll {
		border-color: transparent;
		background: $color-main;
		border-radius: 10px;
		color:$color-white;
		padding: 5px 20px;
		position: relative;
		font-size: 14px;
		text-align: center;

		&:hover {
			opacity: 1;
		}
	}

	&--white{
		border:none;
		color:$color-red;

		&::after{
			border-color:$color-red;
		}

		&:hover{
			background:$color-red;
		}
	}

	&--disabled {
		pointer-events: none;
		background-color: $color-gray;
		border-color: $color-gray;
		color: $color-white;

		&::after{
			content: none;
		}
	}
}


