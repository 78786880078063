/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  padding: 10px 0;
  border-bottom: 1px solid $color-gray-light;
  background: white;

  &-list{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {

      @include view-at(sp) {
        padding: 5px 0;
        font-size: 10px;
      }
      
      &::after {
        content: '〉';
        padding: 0 5px;
      }

      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
}
