/* --------------------------
テキスト装飾
-------------------------- */
.u-text-accent {
  font-size: 14px;
  line-height: 2;
}

.u-text-accent {
  color: $color-blue-dark;
}

.u-text-important {
  color: $color-red;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}



.u-text-bold {
  font-weight: bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}
.u-text--black {
  color: $color-black;
}
.u-text--white {
  color: $color-white;
}
.u-text--gray {
  color: $color-gray;
}
.u-text--light {
  color: $color-gray-light;
}
.u-text--snow {
  color: $color-gray-snow;
}

.u-text {
  line-height: 2em;
  font-size: 15px;
  font-family: 'Noto Sans CJK JP';

  @include view-at(sp) {
    font-size: 14px;
    line-height: 1.8em;
    text-align: left;
  }

  &-min {
    line-height: 1.5em;
    font-size: 13px;
  
    @include view-at(sp) {
      font-size: 12px;
      line-height: 1.5em;
      text-align: left;
    }
  }
}

.u-text-gold {
  color: $color-main;
}

.u-text--point {
  font-size: 18px;
  text-align: center;
  line-height: 1.8em;
  font-family: 'Noto Serif JP';
  
  @include view-at(sp) {
    line-height: 1.8em;
    font-size: 14px;
    text-align: left;
  }
}

.u-text--point--spleft {
  
  @include view-at(sp) {
    text-align: left !important;
  }
}

.u-text-l { 
  line-height: 2.5em;

  @include view-at(sp) {
    line-height: 1.8em;
  }
}

.u-text-x-large {
  font-size: nth($font-size, 1);
  letter-spacing: 0.06em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
  }
}

.u-text-large {
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 2);
  letter-spacing: 0.05em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
    line-height: nth($line-height-sp, 2);
  }
}

.u-text-bit-large {
  font-size: nth($font-size, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
  }
}

.u-text-default {
  font-size: nth($font-size, 6);
  line-height: 1.5em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: 1.5em;
  }
}

.u-text-small {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.u-text-x-small {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }

  &--sp {
    @include view-at(sp) {
      font-size: nth($font-size-sp, 6);
      line-height: nth($line-height-sp, 6);
    }
  }
}


.u-text-serif {
  font-family: 'Times New Roman', Times, serif
}
