/* --------------------------
見出し
-------------------------- */

.c-h1 {
  margin-top: 190px;
  color: $color-black;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  
  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
    padding: 15px;
    margin-top: 110px;
  }

  small {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
  }
}

.c-h2 {
  color: $color-white;
  margin: 0 auto 70px;
  font-weight: $normal;
  font-size: nth($font-size, 2);
  line-height: 1.3;
  letter-spacing: 0.15em;
  text-indent: 0.2em;

  @include view-at(tab) {
    margin: 0 auto 40px;
  }

  @include view-at(sp) {
    font-size: 16px;
    margin: 0 auto 10px;
    line-height: nth($line-height-sp, 2);
  }

  span.en {
    font-family: "Noto Sans JP";
  }
}

.c-h3 {
  font-size: 18px;
  line-height: 1.8em;
  letter-spacing: 0.08em;
  font-weight: $normal;
  margin-bottom: 20px;
  color: white;

  @include view-at(sp) {
    font-size: 14px;
    margin: 0 ;
  }
}

.c-h4 {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }
}

.c-h5 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h6 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}