@charset "UTF-8";

h1 {
	position: fixed;
	top: 50%;
	left: 30px;
	transform: translateY(-50%);
	z-index: 9999;
	width: 35%;

	@include view-at(sp) {
		display: none;
	}
}

.intro p{
	font-size: 1.5em;
}
.twitter-share-button{
	position: fixed !important;
	z-index: 99;
	right: 149px;
	top: 9px;
}
.hana {
	width: 40%;
	float: left;

	@include view-at(tab) {
		position: absolute;
    top: 50%;
    transform: translateY(-50%);
	}

	@include view-at(sp) {
		width: 100%;
    top: 40px;
    text-align: center;
		
		img {
			width: 70%;
		}
	}
	
	span {
		display: none;

		@include view-at(sp) {
			display: block;
			position: absolute;
			top: 60%;
			width: 60%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, 0%);
		}
	}
}
.item {
	width: 50%;
	float: right;
	padding-right: 65px;

	@include view-at(sp) {
		float: none;
		width: 100%;
		padding: 15px 20px 15px 15px;
		position: absolute;
		top: 22%;
	}
	@include view-at(se) {
		padding: 10px 20px 10px 10px;
	}
}

/* --------------------------------------- */
/* Section 1 */
/* --------------------------------------- */

#section1 {
	img{
		width: 40%;
		position: absolute;
		bottom: 0;
		right: 0;

		@include view-at(sp) {
			width: 100%;
		}
	}

	span {
		display: none;
		@include view-at(sp) {
			position: absolute;
			top: 40%;
			left: 50%;
			width: 70%;
			transform: translate(-50%,-50%);
			display: block;
		}
		
	}
}

/* Section 2
 * --------------------------------------- */


#section2 {
	.list {
		li {
			margin-bottom: 30px;

			@include view-at(laptop) {
				margin-bottom: 20px;
			}
			@include view-at(sp) {
				margin-bottom: 15px;
			}
		}
	}
}

/* Section 3
 * --------------------------------------- */
#section3 {
	.list {
		li {
			margin-bottom: 30px;

			@include view-at(sp) {
				margin-bottom: 15px;
			}
		}
	}
}

/* Section 4
 * --------------------------------------- */
#section4 {
	.map {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;

		@include view-at(sp) {
			margin-bottom: 15px;
		}

		&-text {
			width: 40%;

			@include view-at(tab) {
				width: 100%;
				margin-bottom: 10px;
			}
			@include view-at(sp) {
				margin-bottom: 0px;
			}
		}
		&-google {
			width: 60%;
			height: 250px;

			@include view-at(tab) {
				width: 100%;
				height: 100px;
			}

			iframe {
				height: 100%;

				@include view-at(tab) {
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}
	}
	
}

/* Section 5
 * --------------------------------------- */
 #section5 {
	 #form {
		 text-align: center;
	 }
	.form-item {
		margin-bottom: 20px;
    display: flex;
		flex-wrap: wrap;

		@include view-at(sp) {
			margin-bottom: 10px;
		}
		@include view-at(se) {
			margin-bottom: 5px;
		}
		
		p {
			width: 30%;
			font-size: 16px;

			@include view-at(laptop) {
				width: 40%;
			}
			@include view-at(tab) {
				width: 100%;
				margin-bottom: 5px;
			}

			@include view-at(sp) {
				font-size: 12px;
			}

			span {
				font-size: 10px;
			}
		}
		input {
			width: 70%;
			background: #E0DFDC;
			border: none;

			@include view-at(laptop) {
				width: 60%;
			}

			@include view-at(tab) {
				width: 100%;
			}
		}
		select {
			width: 70%;
			background: #E0DFDC;
			border: none;

			@include view-at(laptop) {
				width: 60%;
			}

			@include view-at(tab) {
				width: 100%;
			}
		}
		
	}

	p {
		font-size: 16px;

		@include view-at(sp) {
			font-size: 12px;
		}

		span {
			font-size: 10px;
		}
	}

	.send {
		background: #E0DFDC;
		border: none;
		margin: 20px auto;
		padding: 10px 50px;

		@include view-at(sp) {
			margin: 10px auto;
			width: 100%;
		}

		&:hover {
			background: gray;
			color: white;
		}
	}
	
	.textarea {
		border: none;
		border-radius: 0;
		background: #E0DFDC;
		width: 100%;
		padding: 8px 5px;
		height: 100px;
		border-radius: 3px;

		@include view-at(sp) {
			height: 40px;
		}
	}
	.item {
    @include view-at(sp) {
			top: 20%;
		}
	}
}

/* Overwriting fullPage.js tooltip color
* --------------------------------------- */
#pp-nav.custom .pp-tooltip{
	color: #AAA;
}


.twitter-share-button{
	position: fixed;
	z-index: 99;
	right: 149px;
	top: 9px;
}
#starGithub{
	position: fixed;
	z-index: 99;
	right: 264px;
	top: 9px;
	width: 90px;
}

#pp-nav.right {
	@include view-at(sp) {
		right: 0px;
	}
	
}

#pp-nav li, .pp-slidesNav li {
	@include view-at(sp) {
		margin: 5px 3px;
	}
}




	