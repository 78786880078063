/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
	display: none;
	@include view-at(tab) {
		display: block;
		z-index: 10002;
	}

	&-trigger,
	&-trigger span {
		display: inline-block;
		transition: all .2s;
		box-sizing: border-box;
	}

	&-trigger {
		position: relative;
		width: 22px;
		height: 16px;
	}

	&-trigger span {
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $color-red;
	}

	&-trigger span:nth-of-type(1) {
		top: 0;
	}

	&-trigger span:nth-of-type(2) {
		top: 7px;
	}

	&-trigger span:nth-of-type(3) {
		bottom: 0;
	}

	&-trigger.active span:nth-of-type(1) {
		transform: translateY(7px) rotate(-45deg);
	}

	&-trigger.active span:nth-of-type(2) {
		opacity: 0;
	}

	&-trigger.active span:nth-of-type(3) {
		transform: translateY(-7px) rotate(45deg);
	}
}
