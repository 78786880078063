.p-gnav {
  width: 100%;
  font-family: "GFS Didot", serif;
  background: $color-white;

  @include view-at(tab) {
    position: fixed;
    top: 54px;
    left: 0;
    width: 100%;
    max-width: 100%;
    z-index: +1;
    overflow-x: hidden;
  }

  nav {

    @include view-at(tab) {
    }

    ul {
      display: flex;

      @include view-at(tab) {
        display: none;
        height: auto;
      }

      li {
        width: 100%;
        border-left: 1px solid #c7c7c7;

        @include view-at(tab) {
          width: 50%;
          float: left;
          border-bottom: 1px solid #c7c7c7;
        }
        &:nth-last-child(1) {

          @include view-at(tab) {
            border-right: none;
          }
        }
        &:nth-last-child(5) {
          border-right: 1px solid #c7c7c7;

          @include view-at(tab) {
            border-right: none;
          }
        }

        a {
          text-align: center;
          line-height: 1.5;
          display: block;
          position: relative;
          padding: 5px;

          @include view-at(tab) {
            padding: 15px;
            text-align: left;
            line-height: 1;
          }

          span {
            display: block;
            font-size: 15px;
            line-height: 1;
            padding-bottom: 15px;
            background-image: url(../images/common/nav.png);
            background-position: 0% 80%;
            background-repeat: no-repeat;
            background-size: contain;

            @include view-at(tab) {
              display: none;
            }

            &.green, &.orange, &.pink {
              font-size: 13px;
              padding: 3px;

              @include view-at(tab) {
                display: block;
                background: none !important;
                padding: 0;
              }
            }
            &.green {
              background: #b4d39e;
            }
            &.orange {
              background: #f3ce92;
            }
            &.pink {
              background: #e5a0a1;
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $color-white;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: .3s;
            transition: .3s;
          }

          &:hover{
            opacity: 1;
            transition: 0.5s ;

            @include view-at(tab) {
              color: $color-main;
              background-color: $color-white;
            }

            &::after{
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: $color-white;
              opacity: 1;
              visibility: visible;

              @include view-at(tab) {
                height: 0px;
              }
            }
          }
        }

        > div {
          width: 100%;
          overflow: hidden;
          transition: 0.5s;
          height: 0px;
          position: absolute;
          right: 0;
          left: 0;
          top: 70px;
          background: rgba(91, 169, 208, 0.64);

          @include view-at(tab) {
            display: none;
          }

          > ul {
            margin: 0 auto;
            max-width: 1020px;
            padding: 12px 10px;

            li {
              width: 125px;
              background: #fff;
              margin: 0 5px;

              a {
                span {
                  background-image: none;
                  padding: 5px 0px 0px 0px;
                  font-size: 13px;
                }

                &:hover {
                  opacity: 0.6;
                }
              }
            }
          }
        }

        &:hover {
          > div {
            height: 125.5px;
            z-index: 99;
          }
        }

        &.nav03 {
          > div {
            ul {
              li {
                &:nth-child(1) {
                  margin-left: 252px;
                }
              }
            }
          }
        }

        &.nav04 {
          > div {
            ul {
              li {
                &:nth-child(1) {
                  margin-left: 370px;
                }
              }
            }
          }

          &:hover {
            > div {
              height: 135.5px;
              z-index: 99;
            }
          }
        }

        &.nav07 {
          border-right: 1px solid #c7c7c7;
          
          @include view-at(tab) {
            width: 100%;
          }
          > div {
            ul {
              justify-content: flex-end;

              li {
                &:nth-last-child(1) {
                  margin-right: 0;
                }
              }
            }
          }

          &:hover {
            > div {
              height: 135.5px;
              z-index: 99;
            }
          }
        }
      }
    }
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  background: white;

  @include view-at(tab) {
    position: static;
  }
}