/* --------------------------
ボタン
-------------------------- */

.c-box {
	&-blue{
		display: inline-block;
		font-family: 'Noto Serif JP';
    background: #ACCBE2;
    width: 200px;
    padding: 15px 0;
    font-size: 24px;
    text-align: center;
		margin: 0 10px;
		
		@include view-at(sp) {
			font-size: 18px;
			width: auto;
			padding: 5px 10px;
		}
	}

	&-pink{
		display: inline-block;
		font-family: 'Noto Serif JP';
    background: #EFCCBB;
    width: 200px;
    padding: 15px 0;
    font-size: 24px;
    text-align: center;
		margin: 0 10px;
		
		@include view-at(sp) {
			font-size: 18px;
			width: auto;
			padding: 5px 10px;
		}
	}

	&-green{
		display: inline-block;
		font-family: 'Noto Serif JP';
    background: #C0D1AF;
    width: 200px;
    padding: 15px 0;
    font-size: 24px;
    text-align: center;
		margin: 0 10px;

		@include view-at(sp) {
			font-size: 18px;
			width: auto;
			padding: 5px 10px;
		}
	}
} 