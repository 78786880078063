/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-main;
  color: $color-white;

  p.ttl {
    font-size: 20px;
    text-align: center;
    padding: 20px;
  }

  &-sub {
    background-color: $color-sub;
    color: $color-white;
    padding: 0 10px;

    p.title {
      font-size: 20px;
      font-family: 'Noto Sans JP';
      text-align: center;
      padding: 5px;
    }
  }

  &--gra {
    background: -moz-linear-gradient(left, #1FA8BC, #1987D7);
		background: -webkit-linear-gradient(left, #1FA8BC, #1987D7);
		background: linear-gradient(to right, #1FA8BC, #1987D7);
    color: $color-white;

    p.title {
      font-size: 20px;
      font-family: 'Noto Serif JP';
      text-align: center;
      line-height: 1.5em;
      padding: 10px;

      @include view-at(sp) {
        font-size: 18px;
      }

      span {
        font-size: 50%;
      }
    }
  }

  &-snow {
    background: url(../images/common/sub-bg.jpg) no-repeat center bottom;
    background-size: cover;
    padding: 20px 20px 40px;
  }

  &--border {
    border: 1px solid $color-gray;
    background-color: $color-white;
  }

  &--rounded {
    border-radius: 8px;
  }

  &-contents {
    padding: 20px 40px 40px;

    @include view-at(sp) {
      padding: 10px 20px 20px;
    }

    &--narrow {
      padding: 15px;
    }
  }

  &-inner {
    padding: 20px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 0;

    span {
      font-size: 1.5em;
    }
  }

  &-plan {
    background: $color-brown-snow;
    color: $color-text;
  }
}
